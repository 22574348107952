import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../Config";
import PageTitle from "../PageTitle";
import AdminCard from "../AdminCard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminRightsCheck from "../AdminRightsCheck";
import { NumberFormat } from "../../Dashboard/General/Functions";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function BalanceStatisticsDashboard() {

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);
  const [Dashboard_data, setDashboard_data] = useState('');

  const navigate = useNavigate();
  useEffect(() => {

    document.title = "Mag Holdings Admin";
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    // console.log("data data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetBalanceTrasnferAdminDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setDashboard_data(response.data);
          setLoading(false);
        } else if (response.data.status_code === 0 || response.data.status_code === 2) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);


  return (
    <>
      <AdminRightsCheck />
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <ToastContainer />
          <PageTitle title="ADMIN FUNDS STATISTICS" />
          <div className="admin-content">

            <div className="row">
              <div className="col-md-3">
                <AdminCard
                  link="/admin/balance-statistics-dashboard/total-funds-transfer"
                  class="red"
                  value={"PKR " + NumberFormat(Dashboard_data.totalTransfer)}
                  label="Total Admin Funds Transfer"
                  icon="send_money"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/balance-statistics-dashboard/total-funds-transfer"
                  class="red"
                  value={"PKR " + NumberFormat(Dashboard_data.freeFundsTransfer)}
                  label="FREE FUNDS TRANSFER"
                  icon="chat_paste_go"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/balance-statistics-dashboard/total-funds-transfer"
                  class="red"
                  value={"PKR " + NumberFormat(Dashboard_data.paidFundsTransfer)}
                  label="PAID FUNDS TRANSFER"
                  icon="paid"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/balance-statistics-dashboard/total-funds-transfer"
                  class="red"
                  value={"PKR " + NumberFormat(Dashboard_data.reducedTransfer)}
                  label="REDUCE FUNDS"
                  icon="call_received"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/balance-statistics-dashboard/total-funds-transfer"
                  class="red"
                  value={"PKR " + NumberFormat(Dashboard_data.advanceFundTransfer)}
                  label="ADVANCE FUNDS TRANSFER"
                  icon="target"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/balance-statistics-dashboard/total-funds-transfer"
                  class="blue"
                  value={"PKR " + NumberFormat(Dashboard_data.recoveredFunds)}
                  label="RECOVERED FUNDS"
                  icon="local_mall"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/balance-statistics-dashboard/total-funds-transfer"
                  class="blue"
                  value={"PKR " + NumberFormat(Dashboard_data.leadershipCommissionTransfer)}
                  label="Leadership commission transfer"
                  icon="group"
                  subheading="Members"
                  no="(50)"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BalanceStatisticsDashboard;
