import React, { useEffect, useState } from "react";
import PageTitle from "./PageTitle";
import AdminCard from "./AdminCard";
import { toast, ToastContainer } from "react-toastify";
import config from "../../Config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { NumberFormat } from "../../components/Dashboard/General/Functions";
import AdminRightsCheck from "./AdminRightsCheck";


function AdminDashboard(props) {

  const [AccessKey, setAccessKey] = useState(
    localStorage.getItem("AdminAccessKey")
  );
  const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));

  const [loading, setLoading] = useState(false);
  const [Dashboard_data, setDashboard_data] = useState('');


  useEffect(() => {
    document.title = "Mag Holdings Admin";
  }, []);

  let navigate = useNavigate();

  useEffect(() => {


    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    // console.log("data data", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetMainDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setDashboard_data(response.data);
          setLoading(false);
        } else if (response.data.status_code === 0 || response.data.status_code === 2) {
          setLoading(false);
          toast.error(response.data.status_code, {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else if (response.data.status_message === "Invalid Access Key.") {
          navigate("/admin/login");
        } else {
          toast.error(response.data.status_message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <AdminRightsCheck />

      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "20%" }}
        >
          <Spinner animation="border" role="status"></Spinner>
        </div>
      ) : (
        <>
          <ToastContainer />
          <PageTitle title="Mag Holdings DASHBOARD" />
          <div className="admin-content">
            <div className="row">
              <div className="col-md-3">
                <AdminCard
                  link="/admin/userstatistics-dashboard/Total-user"
                  class="blue"
                  value={(Dashboard_data.totalMember)}
                  label="Total Member"
                  icon="supervisor_account"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/Members-overview"
                  class="red"
                  value={"PKR " + NumberFormat(Dashboard_data.memberCashBalance)}
                  label="Paid Member Cash Balance"
                  icon="credit_card"
                  no="(50)"
                  subheading="Members"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.freeMemberStatusBalance)}
                  label="Fee Member Cash Balance"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.paidMemberStatusHolding)}
                  label="Paid Member Holding Balance"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.freeMemberStatusHolding)}
                  label="Free Member Holding Balance"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>
             
              <div className="col-md-3">
                <AdminCard
                  link="/admin/deposit-dashboard"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.totalDeposit)}
                  label="Total Deposit"
                  icon="vertical_align_bottom"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/withdrawal-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.totalWithdrawPaid)}
                  label="Total withdrawal paid"
                  icon="payments"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/withdrawal-dashboard"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.pendingWithdraw)}
                  label="Pending withdrawal"
                  icon="pending"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="blue"
                  value={"PKR " + NumberFormat(Dashboard_data.totalMembership)}
                  label="Total Packages"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.Starter)}
                  label="Starter Package"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.Standard)}
                  label="Standard Package"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.Superior)}
                  label="Superior Package"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.Freedom)}
                  label="Freedom Package"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.Leader)}
                  label="Leader Package"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>


              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.Gold)}
                  label="Gold Package"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.Diamond)}
                  label="Diamond Package"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.Platinum)}
                  label="Platinum Package"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/total-packages-dashboard"
                  class="brown"
                  value={"PKR " + NumberFormat(Dashboard_data.Titanium)}
                  label="Titanium Package"
                  icon="deployed_code"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/active-bonus-dashboard"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.totalGeneratedBonus)}
                  label="Total Paid Bonus"
                  icon="monetization_on"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/passive-bonus-dashboard"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.totalProfitWalletPaid)}
                  label="Total Profit Wallet Paid"
                  icon="monetization_on"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/passive-bonus-dashboard"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.totalProfitWalletPending)}
                  label="Total Profit Wallet Pending"
                  icon="monetization_on"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/admin-fee-dashboard"
                  class="blue"
                  value={"PKR " + NumberFormat(Dashboard_data.totalAdminFee)}
                  label="Total admin fee"
                  icon="finance_chip"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/Promo-dashboard"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.totalGeneratedVoucher)}
                  label="Promo Paid"
                  icon="new_label"
                  subheading="Members"
                  no="(50)"
                />
              </div>
              <div className="col-md-3">
                <AdminCard
                  link="/admin/Promo-dashboard"
                  class="blue"
                  value={"PKR " + NumberFormat(Dashboard_data.totalPromoPaid)}
                  label="Promo Pending"
                  icon="label"
                  subheading="Members"
                  no="(50)"
                />
              </div>

              <div className="col-md-3">
                <AdminCard
                  link="/admin/dashboard/total-member-earning"
                  class="green"
                  value={"PKR " + NumberFormat(Dashboard_data.totalMembersEarning)}
                  label="Total Member Earning"
                  icon="clock_loader_60"
                  subheading="Members"
                  no="100"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AdminDashboard;
